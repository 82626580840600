<template>
  <div style="text-align: left">{{ msg }}</div>
  <el-form
    :model="seachForm"
    :inline="true"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
  >
    <el-form-item label="订单编号">
      <el-input
        v-model="seachForm.orderCode"
        clearable
        size="small"
        placeholder="请输入订单编号！"
      ></el-input>
    </el-form-item>
    <el-form-item label="订单类型">
      <el-select
        v-model="seachForm.orderType"
        clearable
        size="small"
        placeholder="请输入订单类型"
      >
        <el-option
          v-for="(item, index) in orderTypeOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="手机号">
      <el-input
        size="small"
        v-model="seachForm.cellphone"
        clearable
        placeholder="请输入手机号！"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      ></el-form-item
    >
  </el-form>
  <el-table :data="tableData">
    <el-table-column type="expand">
      <template #default="props">
        <el-form
          label-position="left"
          inline
          class="demo-table-expand"
          style="display: flex; flex-wrap: wrap; flex-direction: row"
        >
          <el-form-item label="收货人" style="width: 45%">
            <span>{{ props.row.linkman }}</span>
          </el-form-item>
          <el-form-item
            label="团长电话"
            style="width: 45%"
            v-if="props.row.orderType === 'GROUPORDER'"
          >
            <span>{{ props.row.managerPhone }}</span>
          </el-form-item>
          <el-form-item label="地址" style="width: 45%">
            <span>{{ props.row.address }}</span>
          </el-form-item>
        </el-form>
      </template>
    </el-table-column>
    <el-table-column label="订单编号" prop="orderCode"></el-table-column>
    <el-table-column label="订单类型" prop="orderTypeVal"> </el-table-column>
    <el-table-column label="订单状态" prop="orderStateVal">
      <template #default="scope">
        <el-tag
          v-if="
            scope.row.orderState === 'DELIVERYED' ||
            scope.row.orderState === 'TODO'
          "
          type="warning"
          >{{
            scope.row.orderState === "DELIVERYED"
              ? scope.row.orderStateVal
              : "待发货"
          }}</el-tag
        >
        <el-tag v-else-if="scope.row.orderState === 'CANCELLED'" type="info">{{
          scope.row.orderStateVal
        }}</el-tag>
        <el-tag v-else-if="scope.row.orderState === 'NEWCREATED'" type="">{{
          scope.row.orderStateVal
        }}</el-tag>
        <el-tag v-else type="success">{{ scope.row.orderStateVal }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="联系电话" prop="cellphone"> </el-table-column>

    <el-table-column label="创建时间" prop="createDate"> </el-table-column>
    <el-table-column label="金额（元）" prop="totalMoney"> </el-table-column>
    <el-table-column label="数量（件）" prop="totalNumber"> </el-table-column>

    <el-table-column label="备注" prop="note"></el-table-column>

    <el-table-column label="操作">
      <template #default="scope">
        <!-- <div> -->
        <el-button
          type="text"
          v-if="
            scope.row.orderType !== 'CLASSORDER' &&
            scope.row.orderState === 'TODO'
          "
          @click="ordersend(scope.row)"
          >订单发货</el-button
        >
        <el-button
          type="text"
          @click="orderDetail(scope.row)"
          style="margin-right: 10px"
          >订单详情</el-button
        >
        <!-- </div>
        <div> -->
        <el-button
          v-if="
            scope.row.orderState === 'TODO' ||
            scope.row.orderState === 'NEWCREATED'
          "
          style="margin-left: 0px !important"
          type="text"
          @click="deleteorder(scope.row)"
          >取消订单</el-button
        >
        <!-- </div> -->
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      msg: '全部订单',
      seachForm: {
        orderCode: '',
        orderType: '',
        cellphone: ''
      },
      orderTypeOption: [],
      tableData: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '订单管理',
        index: '/order/all'
      },
      {
        name: '订单列表',
        index: '/order/all'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getOrder(1)
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (to.params.statu === 'all') {
          this.getOrder(1)
          this.msg = '全部订单'
        } else if (to.params.statu === 'delivery') {
          this.getOrder(1, '?orderState=TODO')
          this.msg = '待发货'
        } else if (to.params.statu === 'receipt') {
          this.getOrder(1, '?orderState=DELIVERYED')
          this.msg = '待收货'
        } else if (to.params.statu === 'pay') {
          this.getOrder(1, '?orderState=NEWCREATED')
          this.msg = '待付款'
        } else if (to.params.statu === 'complete') {
          this.getOrder(1, '?orderState=DONE')
          this.msg = '已完成'
        } else if (to.params.statu === 'cancel') {
          this.getOrder(1, '?orderState=CANCELLED')
          this.msg = '已取消'
        }
      }
    }
  },
  methods: {
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.orderCode.trim() === '' ? '' : 'orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : (this.seachForm.orderCode.trim() === '' ? '' : '&') + 'orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : (this.seachForm.orderCode.trim() === '' && this.seachForm.orderType.trim() === '' ? '' : '&') + 'cellphone=' + this.seachForm.cellphone)
      console.log(str)
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.msg = '全部订单'
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/1')
      console.log(res.data)
      this.seachForm.orderCode = ''
      this.seachForm.orderType = ''
      this.seachForm.cellphone = ''
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.msg = '全部订单'
    },
    async getapplyType() {
      const res = await this.$http.get('/nktdec/orderinfo/ordertypes')
      console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.orderTypeOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/1' + '?type=GROUP&pageSize=' + val + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : '&orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone))
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?&pageSize=' + this.pageSize + (this.seachForm.orderCode.trim() === '' ? '' : '&orderCode=' + this.seachForm.orderCode) + (this.seachForm.orderType.trim() === '' ? '' : '&orderType=' + this.seachForm.orderType) + (this.seachForm.cellphone.trim() === '' ? '' : '&cellphone=' + this.seachForm.cellphone)
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    },
    orderDetail(row) {
      console.log('前往详情页', row)
      this.$router.push('/orderdetaile/' + row.id)
    },
    async ordersend(item) {
      const res = await this.$http.put('/nktdec/orderinfo/deliveryorder/' + item.id)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('发货成功！')
      }
    },
    deleteorder(row) {
      console.log('取消订单', row)
      this.$confirm('此操作取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.put('/nktdec/orderinfo/cancelorder/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('取消成功！')
          this.getOrder(1)
        }
      })
    },
    async getOrder(pageno, str) {
      const res = await this.$http.get('/nktdec/orderinfo/orderinfos/' + pageno + (str || ''))
      console.log(res.data.content)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    }
  }
}
</script>
<style scoped>
</style>
